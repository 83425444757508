import { CokiesPolicyPageDTO } from '~/pages/cookies-policy/types';

import introSection from './introSection';
import whatSection from './whatSection';
import howSection from './howSection';
import thirdPartySection from './thirdPartySection';
import deleteSection from './deleteSection';
import whereSection from './whereSection';

const cokiesPolicyPage: CokiesPolicyPageDTO = {
  introSection,
  whatSection,
  howSection,
  thirdPartySection,
  deleteSection,
  whereSection,
};

export default cokiesPolicyPage;
