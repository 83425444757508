import React from 'react';

import Layout from '~/components/Layout';
import cookiesPolicyPageData from '~/data/cookies-policy';
import Intro from '~/pages/cookies-policy/components/Intro';
import What from '~/pages/cookies-policy/components/What';
import How from '~/pages/cookies-policy/components/How';
import ThirdParty from '~/pages/cookies-policy/components/ThirdParty';
import Delete from '~/pages/cookies-policy/components/Delete';
import Where from '~/pages/cookies-policy/components/Where';

import styles from './CookiesPolicy.module.scss';

const CookiesPolicy = () => {
  const {
    introSection,
    whatSection,
    howSection,
    thirdPartySection,
    deleteSection,
    whereSection,
  } = cookiesPolicyPageData;

  return (
    <Layout invertNavigation>
      <section className={styles.root}>
        <Intro data={introSection} />
        <What data={whatSection} />
        <How data={howSection} />
        <ThirdParty data={thirdPartySection} />
        <Delete data={deleteSection} />
        <Where data={whereSection} />
      </section>
    </Layout>
  );
};

export default CookiesPolicy;
