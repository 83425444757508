import { DeleteSectionDTO } from '~/pages/cookies-policy/types';

const deleteSection: DeleteSectionDTO = {
  title: 'How to delete cookies?',
  description:
    "If you'd like to delete cookies or instruct your web browser to delete or refuse cookies, please visit the help pages of your web browser.",
  note:
    'Please note, however, that if you delete cookies or refuse to accept them, you might not be able to use all of the features we offer, you may not be able to store your preferences, and some of our pages might not display properly.',
};

export default deleteSection;
