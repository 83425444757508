import React from 'react';

import { IntroSectionDTO } from '~/pages/cookies-policy/types';

import styles from './../../CookiesPolicy.module.scss';
import Button from '~/components/Button';

export interface Props {
  data: IntroSectionDTO;
}

const Intro: React.FC<Props> = ({
  data: {
    image,
    title,
    updatedAt,
    updatedAtLabel,
    link,
    linkLabel,
    beforeLink,
    afterLink,
  },
}) => (
  <>
    <img className={styles.cookie} src={image.src} alt={image.alt} />
    <h1 className={styles.title}>{title}</h1>
    <time dateTime={updatedAt} className={styles.date}>
      {`${updatedAtLabel} ${updatedAt}`}
    </time>
    <p className={styles.paragraph}>
      {beforeLink}
      <Button as={{ tag: 'a', href: link }} isText>
        {linkLabel}
      </Button>
      {afterLink}
    </p>
  </>
);

export default Intro;
