import React from 'react';

import { DeleteSectionDTO } from '~/pages/cookies-policy/types';

import styles from './../../CookiesPolicy.module.scss';

export interface Props {
  data: DeleteSectionDTO;
}

const Delete: React.FC<Props> = ({ data: { title, description, note } }) => (
  <>
    <h2 className={styles.subtitle}>{title}</h2>
    <p className={styles.paragraph}>{description}</p>
    <p className={styles.paragraphBigMargin}>{note}</p>
  </>
);

export default Delete;
