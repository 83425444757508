import { WhereSectionDTO } from '~/pages/cookies-policy/types';

const whereSection: WhereSectionDTO = {
  title: 'Where can you find more information about cookies',
  description:
    'You can learn more about cookies and the following third-party websites:',
  urls: [
    {
      text: 'All About Cookies:',
      url: {
        href: 'http://www.allaboutcookies.org',
        text: 'http://www.allaboutcookies.org',
      },
    },
    {
      text: 'Network Advertising Initiative:',
      url: {
        href: 'http://www.networkadvertising.org',
        text: 'http://www.networkadvertising.org',
      },
    },
  ],
};

export default whereSection;
