import { IntroSectionDTO } from '~/pages/cookies-policy/types';
import Cookie from './assets/cookie.svg';

const introSection: IntroSectionDTO = {
  title: 'Cookies Policy',
  image: {
    src: Cookie,
    alt: 'Cookie image',
  },
  updatedAt: '17.06.2020',
  updatedAtLabel: 'Last updated: ',
  link: 'https://www.swing.dev',
  linkLabel: 'https://www.swing.dev',
  beforeLink: 'Swing Development uses cookies on ',
  afterLink:
    '. By using the Service, you consent to the use of cookies. Our Cookies Policy explains what cookies are, how we use cookies, how third-parties we may partner with may use cookies on the Service, your choices regarding cookies and further information about cookies.',
};

export default introSection;
