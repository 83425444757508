import React from 'react';

import { WhereSectionDTO } from '~/pages/cookies-policy/types';

import styles from './../../CookiesPolicy.module.scss';
import Button from '~/components/Button';

export interface Props {
  data: WhereSectionDTO;
}

const Where: React.FC<Props> = ({ data: { title, description, urls } }) => {
  const renderUrls = () =>
    urls.map(({ text, url: { href, text: urlText } }, index) => (
      <p key={index} className={styles.paragraphBigMargin}>
        {text} <br />
        <Button
          as={{
            tag: 'a',
            href: href,
            target: '_blank',
            rel: 'noopener noreferrer',
          }}
          isText
        >
          {urlText}
        </Button>
      </p>
    ));

  return (
    <>
      <h2 className={styles.subtitle}>{title}</h2>
      <p className={styles.paragraph}>{description}</p>
      {renderUrls()}
    </>
  );
};

export default Where;
