import React from 'react';

import { CookiesPolicySection } from '~/pages/cookies-policy/types';

import styles from './../../CookiesPolicy.module.scss';

export interface Props {
  data: CookiesPolicySection;
}

const What: React.FC<Props> = ({ data: { title, description } }) => (
  <>
    <h2 className={styles.subtitle}>{title}</h2>
    <p className={styles.paragraph}>{description}</p>
  </>
);

export default What;
