import { HowSectionDTO } from '~/pages/cookies-policy/types';

const howSection: HowSectionDTO = {
  title: 'How Swing Development uses cookies',
  description: [
    {
      text:
        'When you use and access the Service, we may place a number of cookies files in your web browser. We use cookies for the following purposes:',
      items: [
        'To enable certain functions of the Service,',
        'To provide analytics, to store your preferences,',
        'To enable advertisements delivery,',
        'Including behavioral advertising.',
      ],
    },
    {
      text:
        'We use both session and persistent cookies on the Service and we use different types of cookies to run the Service:',
      items: [
        'Essential cookies. We may use essential cookies to authenticate users and prevent fraudulent use of user accounts.',
      ],
    },
  ],
};

export default howSection;
